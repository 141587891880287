import PropTypes from 'prop-types';
// @mui
import { Box, Link, Stack, Typography, Breadcrumbs, Grid, IconButton } from '@mui/material';
//
import LinkItem from './LinkItem';
import Iconify from '../iconify';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

CustomBreadcrumbs.propTypes = {
  sx: PropTypes.object,
  action: PropTypes.node,
  links: PropTypes.array,
  heading: PropTypes.string,
  moreLink: PropTypes.array,
  activeLast: PropTypes.bool,
};

export default function CustomBreadcrumbs({ links, action, heading, enable_return, moreLink, activeLast, sx, ...other }) {
  const lastLink = links[links.length - 1]?.name;
  const navigate = useNavigate()

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Grid container wrap='nowrap'>
              {enable_return && <Grid item>
                <IconButton onClick={() => navigate(-1)}>
                  <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
              </Grid>}
              <Grid item xs>
                <Typography variant="h4" gutterBottom>
                  {heading}
                </Typography>
              </Grid>
            </Grid>
            
          )}

          {/* BREADCRUMBS */}
          {links && !!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem key={link?.name || ''} link={link} activeLast={activeLast} disabled={link?.name === lastLink} />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return <Box component="span" sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }} />;
}
