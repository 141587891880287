import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import Chart, { useChart } from '../../chart';
import { catalogs } from '../../../utils/catalogs';
import { grey } from '@mui/material/colors';

const GlobalRiskGroupChart = () => {

    let series = [12, 30, 22] 

    let _options = [
        ...catalogs.risk_levels,
        {value:-1, label:'Sin clasificar', color:grey[300]}
    ]

    const chartOptions = useChart({
        labels: _options.map((el) => el.label),
        legend: {
            position:'bottom'
        },
        colors:_options.map((el) => el.color)
    })

    return ( 
        <Box>
            <Typography variant='h5' gutterBottom>
                Clasificación de grupo de riesgo
            </Typography>
            <Box sx={{p:2}}>
                <Box>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    width:'100%',
                                    maxWidth:500,
                                    margin:'auto'
                                }}
                                
                            >
                                <Chart
                                    type="pie" 
                                    series={series} 
                                    options={chartOptions}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{p:5}}>
                            <img src="/assets/images/analytics/riskgrouptable.png" alt="grupo de riesgo tabla" />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
     );
}
 
export default GlobalRiskGroupChart;

/*[
    "3",
    "0",
    "1",
    0
]*/