export const USER_TYPES = {
    ADMIN:1,
    NUTRITIONIST:2,
    CLIENT:3,
    USER:4
}

export const USER_TYPES_TEXT = {
    ADMIN:'admin',
    NUTRITIONIST:'nutritionist',
    CLIENT:'client',
    USER:'user'
}

export const USER_STATUSES = {
    ACTIVE:1,
    SUSPEND:2,
    INVITED:3,
    REGISTERED:4,
    DISABLED:5
}

export const GENDERS = {
    MALE:1,
    FEMALE:2,
}

export const COUNTRIES = {
    MEXICO:1
}

export const CATEGORY_TYPES = {
    PRODUCT:1,
    RECIPE:2,
    EQUIVALENTS:3,
    BLOG:4
}

export const TAG_TYPES = {
    PRODUCT:1,
    RECIPE:2
}

export const DIET_TYPES = {
    MEAL_SELECTION:1,
    SMAE:2,
    EQUIVALENTS:3,
    MENU:4,
    COLUMNS:5,
    OPTIONS:6,
    CUSTOMIZABLE:7
}

export const PRODUCT_TYPES = {
    GENERAL:1,
    EQUIVALENT:2
}

export const ACHIEVEMENT_TYPES = {
    OBJECTIVE:1,
    GOAL:2,
    PRIZE:3
}

export const APPOINTMENT_STATUSES = {
    SCHEDULED:1,
    RESCHEDULED:2,
    CANCELLED:3,
    COMPLETED:4,
    COMPLETED_NO_ASSISTANCE:5

}


export const LANGUAGES = {
    SPANISH: 1,
    ENGLISH: 2
}


export const CURRENCY = {
    USD: 1,
    MXN: 2
}

export const TAXES = {
    IVA: 0.16
}

export const PRODUCT_DISCOUNT_TYPES = {
    DISCOUNT:1,
    BUNDLE_QXQ:2,
    BUNDLE:3
}

export const ROLES = {
    DOCTOR:2,
    PHARMACY_ASSISTANT: 4
}

export const ORDER_TYPES = {
    DELIVERY:1,
    PICKUP:2,
    COUNTER:3
}

export const PAYMENT_METHODS = {
    CREDIT_CARD:1,
    PAYPAL:2,
    MERCADO:3,
    CASH:4,
    TPV:5
}

export const ORDER_PAYMENT_STATUS = {
    PENDING:1,
    DECLINED:2,
    EXPIRED:3,
    PAID:4,
    REFUNDED:5,
    PARTIALLY_REFUNDED:6,
    CHARGED_BACK:7,
    PRE_AUTHORIZED:8,
    VOIDED:9
}

export const ORDER_STATUS = {
    PENDING_PAYMENT:1,
    PENDING_SHIPMENT:2,
    SHIPPED:3,
    DELIVERED:4,
    CANCELLED:5,
    DISPUTED:6,
    RETURNED:7,
    REIMBURSED:8,
    DELIVERY_ISSUE:9,
    PAYMENT_ISSUE:10,
    PICKUP_PENDING:11
}

export const SHIPPING_TYPES = {
    CELUENVIO:2,
    LOCAL:3, 
    NATIONAL:4,
    NONE:1
}

export const DAYS_LABELS = {
    1:'Lunes',
    2:'Martes',
    3:'Miércoles',
    4:'Jueves',
    5:'Viernes',
    6:'Sábado',
    7:'Domingo',
}
