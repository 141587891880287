import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Chart, { useChart } from '../../chart';
import { blue, indigo } from '@mui/material/colors';


const RegionMCBarChart = ({
    data,
    locations=[]
}) => {

    const chartOptions = useChart({

        colors:[blue[300], indigo[600] ],
        dataLabels:{
            enabled:false
        },
        xaxis: {
            categories: locations
        },
    })

    return ( 
        <Box>
            <Typography variant='h6' sx={{fontWeight:600, py:3}} align='center'>
                IMC Promedio por región
            </Typography>
            <Box
                sx={{
                    width:'100%',
                    margin:'auto',
                    position:'relative',
                }}
                
            >
                <Chart
                    height={400}
                    type="bar" 
                    series={data} 
                    options={chartOptions}
                />
            </Box>
        </Box>
     );
}
 
export default RegionMCBarChart;