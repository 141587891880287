import { private_server } from "../utils/axios"

export const getAppointments = async(params) => {
    let result = {
        data:[], 
        count:0
    }
    let request
    request = await private_server.get('/appointment/all', {params:params})
    result.data = request.data.data?.appointments??[]
    result.count = request.data.data.count
    return result
}