// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dash';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/recover-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    users: path(ROOTS_DASHBOARD, '/users'),
    nutriotionists: path(ROOTS_DASHBOARD, '/nutritionists'),
    patients: path(ROOTS_DASHBOARD, '/patients'),
    clients: path(ROOTS_DASHBOARD, '/businesses'),
    offices: path(ROOTS_DASHBOARD, '/offices'),
    blogs: path(ROOTS_DASHBOARD, '/blogs'),
    products: path(ROOTS_DASHBOARD, '/products'),
    recipes: path(ROOTS_DASHBOARD, '/recipes'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  public:{
    appointment: (appointmentid) => path('',`/appointmentlink/${appointmentid}`)
  },
  templates: {
    products: path(ROOTS_DASHBOARD, '/products'),
    diets: path(ROOTS_DASHBOARD, '/diets'),
    recipes: path(ROOTS_DASHBOARD, '/recipes'),
    equivalents: path(ROOTS_DASHBOARD, '/equivalents'),
    achievements: path(ROOTS_DASHBOARD, '/achievements'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    account: (userid) => path(ROOTS_DASHBOARD, `/users/${userid}/account`),
    profile: path(ROOTS_DASHBOARD, '/users/profile'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  nutritionist: {
    root: path(ROOTS_DASHBOARD, '/nutritionists'),
    account: (userid) => path(ROOTS_DASHBOARD, `/nutritionists/${userid}/account`),
  },
  patients: {
    root: path(ROOTS_DASHBOARD, '/patients'),
    account: (userid) => path(ROOTS_DASHBOARD, `/patients/${userid}/account`),
    appointment: (userid, appointmentid) => path(ROOTS_DASHBOARD, `/patients/${userid}/account/appointment/${appointmentid}`),
  },
  businesses: {
    root: path(ROOTS_DASHBOARD, '/businesses'),
    account: (businessid) => path(ROOTS_DASHBOARD, `/businesses/${businessid}/account`),
    program: (businessid, programid) => path(ROOTS_DASHBOARD, `/businesses/${businessid}/program/${programid}`),
  },
  offices: {
    root: path(ROOTS_DASHBOARD, '/offices'),
    account: (officeid) => path(ROOTS_DASHBOARD, `/offices/${officeid}/account`),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    view: (productid) => path(ROOTS_DASHBOARD, `/products/${productid}`),
  },
  equivalents: {
    root: path(ROOTS_DASHBOARD, '/equivalents'),
    view: (productid) => path(ROOTS_DASHBOARD, `/equivalents/${productid}`),
    view_simple: (productid) => path(ROOTS_DASHBOARD, `/equivalents/simple/${productid}`),
    view_smae: (productid) => path(ROOTS_DASHBOARD, `/equivalents/smae/${productid}`),

  },
  recipes: {
    root: path(ROOTS_DASHBOARD, '/recipes'),
    view: (recipeid) => path(ROOTS_DASHBOARD, `/recipes/${recipeid}`),
  },
  diets: {
    root: path(ROOTS_DASHBOARD, '/diets'),
    view_smae: (recipeid) => path(ROOTS_DASHBOARD, `/diets/smae/${recipeid}`),
    view_equivalents: (recipeid) => path(ROOTS_DASHBOARD, `/diets/simplifiedequivalents/${recipeid}`),
    view_menus: (recipeid) => path(ROOTS_DASHBOARD, `/diets/menus/${recipeid}`),
    view_columns: (recipeid) => path(ROOTS_DASHBOARD, `/diets/columns/${recipeid}`),
    view_options: (recipeid) => path(ROOTS_DASHBOARD, `/diets/options/${recipeid}`),


  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    add: path(ROOTS_DASHBOARD, '/blogs/add'),
    view: (idblog) => path(ROOTS_DASHBOARD, `/blogs/${idblog}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1';
