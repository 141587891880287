import { Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import Iconify from '../iconify';
import moment from 'moment';

const RangeDateHeader = ({
    title,
    start_date,
    end_date,
    onUpdate
}) => {

    const [periodName, setPeriodName] = useState("")

    useEffect(() => {
        const temp = moment(start_date)
        const month = temp.month()
        const year = temp.format('YYYY')
        setPeriodName(`${monthsLabel[month]} ${year}`)
    }, [start_date])

    const onPrevious = useCallback(() => {
        const tempstart = moment(start_date).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        const tempend = moment(start_date).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        let data2update = {
            start_date: tempstart,
            end_date: tempend,
        }
        onUpdate(data2update)
    }, [start_date, end_date])

    const onNext = useCallback(() => {
        const tempstart = moment(start_date).add(1, 'month').startOf('month').format('YYYY-MM-DD')
        const tempend = moment(start_date).add(1, 'month').endOf('month').format('YYYY-MM-DD')
        let data2update = {
            start_date: tempstart,
            end_date: tempend,
        }
        onUpdate(data2update)
    }, [start_date, end_date])

    const onStartChange = useCallback((e) => {
        console.log(e.target.value)
        let data2update = {
            start_date: e.target.value,
            end_date: end_date,
        }
        onUpdate(data2update)
    }, [start_date, end_date])

    
    const onEndChange = useCallback((e) => {
        console.log(e.target.value)
        let data2update = {
            start_date: e.target.value,
            end_date: end_date,
        }
        onUpdate(data2update)
    }, [start_date, end_date])

    return ( 
        <Box sx={{pb:2}}>
            <Stack direction={'row'} spacing={2} alignItems='center'>
                <Box sx={{
                    flex:1
                }}>
                    <Typography variant='h5'>
                        {title}
                    </Typography>
                    <Typography>
                        {periodName}
                    </Typography>
                </Box>
                
                <Stack direction={'row'} spacing={2} alignItems='center'>
                    <Tooltip title='Mes anterior'>
                        <IconButton onClick={onPrevious}>
                            <Iconify icon="solar:skip-previous-bold-duotone" />
                        </IconButton>
                    </Tooltip>
                    <TextField 
                        value={start_date}
                        label='Fecha de inicio'
                        variant='filled'
                        type='date'
                        InputLabelProps={{shrink:true}}
                        onChange={onStartChange}
                    />
                    <TextField 
                        value={end_date}
                        label='Fecha de fin'
                        variant='filled'
                        type='date'
                        InputLabelProps={{shrink:true}}
                        onChange={onEndChange}
                    />
                    <Tooltip title='Mes siguiente'>
                        <IconButton  onClick={onNext}>
                            <Iconify icon="solar:skip-next-bold-duotone" />
                        </IconButton>
                    </Tooltip>
                </Stack>
                
            </Stack>
        </Box>
     );
}
 
export default RangeDateHeader;

const monthsLabel = [
    "Enero",     // Índice 0
    "Febrero",   // Índice 1
    "Marzo",     // Índice 2
    "Abril",     // Índice 3
    "Mayo",      // Índice 4
    "Junio",     // Índice 5
    "Julio",     // Índice 6
    "Agosto",    // Índice 7
    "Septiembre",// Índice 8
    "Octubre",   // Índice 9
    "Noviembre", // Índice 10
    "Diciembre"  // Índice 11
  ];