import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import Iconify from '../iconify';
import ComingSoonIllustration from '../../assets/illustrations/ComingSoonIllustration';
import { useBoolean } from '../../hooks/useBoolean';
import number2Digits from '../../utils/general';
import moment from 'moment';
import { getAnalyticsLocationAverageMetrics } from '../../services/analyticsService';
import { getClientLocations } from '../../services/clientService';
import RegionMCBarChart from './region/RegionIMCBarChart';
import LoadingContainer from '../loaders/LoadingContainer';
import CustomSmallSelect from '../custom-input/CustomSmallSelect';
import RegionIMCbyLocation from './region/RegionIMCbyLocation';
import { blueGrey, indigo } from '@mui/material/colors';
import { fNumber } from '../../utils/formatNumber';


const RegionReport = ({
    idclient, 
    idclientprogram, 
    period,
    onReturn
}) => {

    const loading = useBoolean()
    const [data, setDate] = useState()

    const [imc_data, setIMCData] = useState([
        {
            name:'Anterior',
            group:'previous',
            data:[],
        },
        {
            name:'Actual',
            group:'current',
            data:[],
        },
    ])

    const [locations, setLocations] = useState([])
    const [locations_data, setLocationsData] = useState([])
    const [selected, setSelected] = useState()
    const [current_analytics, setCurrentAnalytics] = useState([])

    const _location = locations_data.find(el => el.id_client_location === selected)
    const _analytic = current_analytics.find(el => el.client_location_id === selected)

    console.log(_analytic)
    

    const initialize = useCallback(async() => {
            const month = number2Digits((period?.month ?? 0)+1)
            const year = period?.year
            const startdate = `${year}-${month}-01` 
            const enddate = moment(startdate).endOf('month').format('YYYY-MM-DD')

            const startdate_previous = moment(startdate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
            const enddate_previous = moment(startdate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    
            loading.onTrue()

            
            try {

                const _locations = await getClientLocations(idclient, {order:0, order_by:JSON.stringify(['name'])})
                console.log(_locations)
                setLocationsData(_locations.data)
                if(_locations.data ) setSelected(_locations.data[0].id_client_location)
                const _locationslabel = _locations.data.map(item => item.name)
                setLocations(_locationslabel)

    
                const _locatationdata_current = await getAnalyticsLocationAverageMetrics({
                    start_date:startdate,
                    end_date:enddate,
                    client_id:idclient,
                    client_program_id:idclientprogram
                })

                const _locatationdata_prev = await getAnalyticsLocationAverageMetrics({
                    start_date:startdate_previous,
                    end_date:enddate_previous,
                    client_id:idclient,
                    client_program_id:idclientprogram
                })
                setCurrentAnalytics(_locatationdata_current)
                //console.log(_patientdata)
                //setGlobalPatient(_patientdata)

                let _imc_prev = []
                let _imc_current = []

                for(let i=0; i<_locations.data.length; i++){
                    const _location = _locations.data[i]
                    let _findPrev = _locatationdata_prev.find(el => el.client_location_id === _location.id_client_location)
                    let _findCurrent = _locatationdata_current.find(el => el.client_location_id === _location.id_client_location)
                    console.log(_findPrev)
                    console.log(_findCurrent)
                    if(_findCurrent){
                        //console.log("Se encontró current")
                        _imc_current.push(Number(_findCurrent?.average_imc ?? 0))
                    }else{
                        _imc_current.push(0)
                    }
                    if(_findPrev){
                        //console.log("Se encontró prev")
                        _imc_prev.push(Number(_findPrev?.average_imc ?? 0))
                    }else{
                        _imc_prev.push(0)
                    }

                }

                setIMCData([
                    {
                        name:'Anterior',
                        group:'previous',
                        data:_imc_prev,
                    },
                    {
                        name:'Actual',
                        group:'current',
                        data:_imc_current,
                    },
                ])
                
            } catch (error) {
                console.log(error)
            }
    
            
            
           
            loading.onFalse()
    
        }, [period, idclient, idclientprogram])
    
        useEffect(() => {
            initialize()
        }, [])


    return ( 
        <Box>
            <Stack direction='row'>
                <IconButton onClick={onReturn}>
                    <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
                <Typography variant='h5'>
                    Datos por Región
                </Typography>
                
            </Stack>
            <LoadingContainer loading={loading.value}>
                <Stack>
                    <RegionMCBarChart 
                        data={imc_data}
                        locations={locations}
                    />
                    <Box>
                        <Stack direction='row' justifyContent='flex-end'>
                            <CustomSmallSelect label='Región' value={selected} onChange={(e) => setSelected(parseInt(e.target.value))}>
                                {locations_data.map((location) => (
                                    <option key={location.id_client_location} value={location.id_client_location}>
                                        {location.name}
                                    </option>
                                ))}
                            </CustomSmallSelect>
                            
                        </Stack>
                        <Box>
                            
                            <Typography variant='h5' gutterBottom>
                                Región {_location?.name}
                            </Typography>
                            <Box sx={{pb:2}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <AverageMetricCard 
                                            title="IMC promedio"
                                            icon="fa-solid:weight"
                                            value={fNumber(_analytic?.average_imc ?? 0, '0.00')}
                                            
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <AverageMetricCard 
                                            title="ICC promedio"
                                            icon="icon-park-outline:waistline"
                                            value={fNumber(_analytic?.average_icc ?? 0, '0.00')}
                                            
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <AverageMetricCard 
                                            title="% grasa promedio"
                                            icon="fa-solid:percentage"
                                            value={fNumber(_analytic?.average_percentage_fat ?? 0, '0.00')}
                                            
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <RegionIMCbyLocation 
                                idclient={idclient} 
                                idclientprogram={idclientprogram} 
                                idclientlocation={selected}
                                period={period}
                            />
                        </Box>
                    </Box>
                </Stack>
                
            </LoadingContainer>
        </Box>
     );
}
 
export default RegionReport;

const AverageMetricCard = ({
    icon,
    title,
    value,
    diagnosis
}) => {
    return(
        <Card sx={{
            p:2
        }}>
            <Grid container>
                <Grid item>
                    <Box sx={{p:1}}>
                        <Iconify icon={icon} color={blueGrey[600]} width={42}/>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Typography variant='subtitle1'>
                        {title} del periodo actual
                    </Typography>
                    <Typography variant='body1' color={'text.secondary'}>
                        {value} {diagnosis}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    )
}