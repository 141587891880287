import { Box, ButtonBase, Card, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import useAppointmentsProgramSection from './useAppointmentsProgramSection';
import TableHeadCustom from '../../../../components/table/TableHeadCustom';
import { onGetDateWithTimezone } from '../../../../utils/formatTime';
import { onGetFullname } from '../../../../utils/general';
import { blueGrey, teal } from '@mui/material/colors';
import TablePaginationCustom from '../../../../components/table/TablePaginationCustom';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import RangeDateHead from '../../../../components/headers/RangeDateHeader';
import { catalogs } from '../../../../utils/catalogs';

const AppointmentsProgramSection = ({
    idclient,
    idclientprogram,
}) => {

    const {
        loading,
        data,
        total,
        pagination,
        period,
        onUpdatePeriod,
        onUpdatePagination
    } = useAppointmentsProgramSection({idclient, idclientprogram})

    const navigate = useNavigate()

    return ( 
        <Box>
            <RangeDateHead
                title="Consultas del periodo"
                start_date={period.start_date}
                end_date={period.end_date}
                onUpdate={onUpdatePeriod}
            
            />
            {(loading.value) && <Box sx={{marginBottom:2}}><LinearProgress /></Box> }
            <Card sx={{overflow:'auto'}}>
                <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                    
                    <Table stickyHeader size={'small'} sx={{ minWidth: 800}}>
                        <TableHeadCustom
                            headLabel={[
                                { id: 'status', label: '', align: '' },
                                { id: 'date', label: 'Fecha', align: '' },
                                { id: 'hour', label: 'Hora', align: 'left' },
                                { id: 'patient', label: 'Paciente', align: 'left' },
                                { id: 'nutritionist', label: 'Nutriólogo', align: 'left' },
                                { id:'height', label:'M1'},
                                { id:'weight', label:'M2'},
                                { id:'imc', label:'M3'},
                                { id:'imc_disagnotisc', label:'M3'},
                                { id:'percentage_fat', label:'M4'},
                                { id:'lean_mass', label:'M5'},
                                { id:'circumference_waist', label:'M6'},
                                { id:'circumference_hips', label:'M7'},
                                { id:'icc', label:'M8'},
                                { id:'icc_objective', label:'M8'},
                                { id:'objective', label:'M9'},
                                { id:'riskgourp', label:'M10'},
                            ]}
                        />
                        <TableBody>
                            {data.map(item => {

                                const _date = onGetDateWithTimezone(item?.start_date, 'DD MMM YY')
                                const _dateTime = onGetDateWithTimezone(item?.start_date, 'HH:mm')
                                const nutritionist = onGetFullname({
                                    first_name:item?.nutritionist_first_name,
                                    fathers_last_name:item?.nutritionist_fathers_last_name,
                                    mothers_last_name:item?.nutritionist_mothers_last_name,
                                })
                                const patient = onGetFullname({
                                    first_name:item?.user_first_name,
                                    fathers_last_name:item?.user_fathers_last_name,
                                    mothers_last_name:item?.user_mothers_last_name,
                                })

                                const _status = catalogs.appointment_statuses.find(el => el.value === item?.appointment_status_id)
                                return(
                                    <TableRow key={`${item.id_appointment}`}>
                                        <TableCell sx={{width:25}}>
                                            <Tooltip title={_status?.label}>
                                                <Box 
                                                    sx={{
                                                        background:_status?.hexColor,
                                                        width:14,
                                                        height:14,
                                                        borderRadius:'50%'
                                                    }}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{width:100}}>
                                            <Typography noWrap variant='body2'>
                                                {_date}
                                            </Typography>
                                            
                                        </TableCell>
                                        <TableCell sx={{width:50}}>
                                            {_dateTime}h
                                        </TableCell>
                                        <TableCell sx={{width:200}}>
                                            <Tooltip title={patient}>
                                                <ButtonBase sx={{alignItems:'flex-start', textAlign:'left'}} onClick={() => navigate(PATH_DASHBOARD.patients.appointment(item?.user_id, item?.id_appointment))}>
                                                    <Typography variant='body2' noWrap width={200}>
                                                        {patient}
                                                    </Typography>
                                                </ButtonBase>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{width:150}}>
                                            <Tooltip title={nutritionist}>
                                                <Typography variant='body2' noWrap width={150}>
                                                    {nutritionist}
                                                </Typography>
                                            </Tooltip>
                                            
                                        </TableCell>
                                        
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Estatura"
                                                value={item?.height}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Peso"
                                                value={item?.weight}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="IMC"
                                                value={item?.imc}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Diagnóstico"
                                                value={item?.imc_diagnostic_id}
                                            />
                                        </TableCell>
                                        
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="% de grasa"
                                                value={item?.percentage_fat}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Masa grasa"
                                                value={item?.lean_mass}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Cintura"
                                                value={item?.circumference_waist}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Cadera"
                                                value={item?.circumference_hips}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="ICC"
                                                value={item?.icc}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="ICC Diagnóstico"
                                                value={item?.icc_diagnostic_id}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Grupo de riesgo"
                                                value={item?.risk_level_id}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MetricStatusCell 
                                                name="Motivo de consulta"
                                                value={item?.objective}
                                            />
                                        </TableCell>
                                        
                                    </TableRow>
                                )

                                
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <Box sx={{marginTop:2}}>
                <TablePaginationCustom   
                    tableFilter={pagination}               
                    total={total}
                    onChangePagination={onUpdatePagination}
                />
            </Box>

        </Box>
     );
}
 
export default AppointmentsProgramSection;

const MetricStatusCell = ({
    name,
    value,
}) => {

    let color = blueGrey[100]
    if(value !==  null && value !==  undefined) color = teal[600]

    return(
        <Tooltip title={name}>
            <Box 
                sx={{
                    background: color,
                    width:24, height:24,
                    borderRadius:0.5
                }}
            />
        </Tooltip>
    )
}