import React from 'react';
import { Box, Typography } from '@mui/material';
import Chart, { useChart } from '../../chart';
import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lime, orange, pink, purple, red, teal } from '@mui/material/colors';
import { fNumber } from '../../../utils/formatNumber';

const GlobalPathologiesChart = ({
    personal_asthma=0,
    personal_cancer=0,
    personal_diabetes=0,
    personal_gastrointestinal_system_diseases=0,
    personal_gout=0,
    personal_heart_diseases=0,
    personal_high_cholesterol=0,
    personal_hypertension=0,
    personal_immunosuppressants_diseases=0,
    personal_obesity=0,
    personal_other=0,
    personal_overweight=0,
    personal_polycystic_ovary_syndrome=0,
    personal_pre_diabetes=0,
    personal_psychological_psychiatric_diseases=0,
    personal_renal_insufficiency=0,
    personal_thyroid_diseases=0,
}) => {

    let series = [
        Number(personal_asthma ?? 0),
        Number(personal_cancer ?? 0),
        Number(personal_diabetes ?? 0),
        Number(personal_gastrointestinal_system_diseases ?? 0),
        Number(personal_gout ?? 0),
        Number(personal_heart_diseases ?? 0),
        Number(personal_high_cholesterol ?? 0),
        Number(personal_hypertension ?? 0),
        Number(personal_immunosuppressants_diseases ?? 0),
        Number(personal_obesity ?? 0),
        Number(personal_other ?? 0),
        Number(personal_overweight ?? 0),
        Number(personal_polycystic_ovary_syndrome ?? 0),
        Number(personal_pre_diabetes ?? 0),
        Number(personal_psychological_psychiatric_diseases ?? 0),
        Number(personal_renal_insufficiency ?? 0),
        Number(personal_thyroid_diseases ?? 0)
    ] 


    const chartOptions = useChart({
        dataLabels: { 
            enabled:true,
            formatter: (val, opts) => {
                return `${fNumber(val, '0.0')}%`;
            },
        },
        labels: [
            "Asma",
            "Cáncer",
            "Diabetes",
            "Enfermedades gastrointestinales",
            "Gota",
            "Enfermedades del corazón, infartos o embolias",
            "Colesterol o triglicéridos altos",
            "Hipertensión",
            "Enf con inmunosupresores",
            "Obesidad",
            "Otros",
            "Sobrepeso",
            "SOP",
            "Pre Diabetes",
            "Problemas psicológicos o psiquiátricos",
            "IRC",
            "Problemas tiroideos"
        ],
        legend: {
            position:'right'
        },
        colors:[
            green[300],
            blue[300],
            purple[300],
            red[300],
            teal[300],
            pink[300],
            amber[300],
            lightBlue[300],
            indigo[300],
            orange[300],
            deepPurple[300],
            orange[300],
            deepOrange[300],
            lime[300],
            cyan[300],
            brown[300],
            blueGrey[200]
        ],
    })

    return ( 
        <Box>
            <Typography variant='h5' gutterBottom>
                Patologías presentes en
                grupo de riesgo
            </Typography>
            <Box sx={{p:2}}>
                <Box
                    sx={{
                        width:'100%',
                        maxWidth:800,
                        margin:'auto'
                    }}
                    
                >
                    <Chart
                        type="donut" 
                        series={series} 
                        options={chartOptions}
                    />
                </Box>
            </Box>
        </Box>
     );
}
 
export default GlobalPathologiesChart;

/*[
    "3",
    "0",
    "1",
    0
]*/