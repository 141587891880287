import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import Iconify from '../iconify';
import number2Digits from '../../utils/general';
import moment from 'moment';
import { getAnalyticsAvergaMetrics, getGlobalPatientCount, getLocationPatientCount, getUsersByObjective } from '../../services/analyticsService';
import LoadingContainer from '../loaders/LoadingContainer';
import useInitState from '../../hooks/useInitState';
import ExecutivePatientInformation from './executive/ExecutivePatientInformation';
import ExecutivePatientsInCare from './executive/ExecutivePatientsInCare';
import ExecutiveLocationCard from './executive/ExecutiveLocationCard';
import ExecutiveObjectivesCard from './executive/ExecutiveObjectivesCard';
import { GENDERS } from '../../utils/enums';

const ExecutiveReport = ({
    idclient, 
    idclientprogram, 
    period,
    onReturn,
    
}) => {

    const {
        loading
    } = useInitState()

    const [global_appointment, setGlobalAppointment] = useState({
        "total_appointments": 7,
        "valid_appointments": "7",
        "scheduled_appointments": "2",
        "rescheduled_appointments": "0",
        "cancelled_appointments": "0",
        "completed_appointments": "5",
        "no_assistance_completed_appointments": "0",
        "appointments_per_user": "1.0000"
    })

    const [global_patient, setGlobalPatient] = useState({"total_users": 3,"initial_appointment_users": "0","scheduled_appointment_users": "2","pending_users": "1","invited_users": "0","registered_users": "0","disabled_users": "0","follow_up_appointment_users": "0"})

    const [location_patient, setLocationPatient] = useState([])
    const [users_objectives, setUsersObjectives] = useState([])
    const [average_metrics, setAverageMetrics] = useState()
    const [average_metrics_female, setAverageFemaleMetrics] = useState()
    const [average_metrics_male, setAverageMaleMetrics] = useState()

    const initialize = useCallback(async() => {
        const month = number2Digits((period?.month ?? 0)+1)
        const year = period?.year
        const startdate = `${year}-${month}-01` 
        const enddate = moment(startdate).endOf('month').format('YYYY-MM-DD')

        let params = {
            start_date:startdate,
            end_date:enddate,
            client_id:idclient,
            client_program_id:idclientprogram
        }

        loading.onTrue()
        try {
            const _patientdata = await getGlobalPatientCount({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id:idclientprogram
            })
            setGlobalPatient(_patientdata)
            
        } catch (error) {
            console.log(error)
        }

        try {
            const _locationdata = await getLocationPatientCount({
                client_id:idclient,
                client_program_id: idclientprogram,
            })
            setLocationPatient(_locationdata)
        } catch (error) {
            console.log(error)
        }

        try {
            const _objectivesdata = await getUsersByObjective({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id: idclientprogram,
            })
            setUsersObjectives(_objectivesdata)
            //setLocationPatient(_locationdata)
        } catch (error) {
            console.log(error)
        }

        try {
            const _averagemetricdata = await getAnalyticsAvergaMetrics({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id: idclientprogram,
            })
            setAverageMetrics(_averagemetricdata)
        } catch (error) {
            console.log(error)
        }

        try {
            const _averagemetricmaledata = await getAnalyticsAvergaMetrics({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id: idclientprogram,
                gender_id:GENDERS.MALE
            })
            setAverageMaleMetrics(_averagemetricmaledata)
        } catch (error) {
            console.log(error)
        }

        try {
            const _averagemetricfemaledata = await getAnalyticsAvergaMetrics({
                start_date:startdate,
                end_date:enddate,
                client_id:idclient,
                client_program_id: idclientprogram,
                gender_id:GENDERS.FEMALE
            })
            setAverageFemaleMetrics(_averagemetricfemaledata)
        } catch (error) {
            console.log(error)
        }
       
        loading.onFalse()

    }, [period, idclient, idclientprogram])

    useEffect(() => {
        initialize()
    }, [])


    return ( 
        <Box>
            <Stack direction='row'>
                <IconButton onClick={onReturn}>
                    <Iconify icon="material-symbols:arrow-back-rounded" />
                </IconButton>
                <Typography variant='h5'>
                    Resumen ejecutivo
                </Typography>
                
            </Stack>
            <Box sx={{py:3}}>
                <LoadingContainer loading={loading.value}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={3}>
                                    <ExecutivePatientInformation 
                                        //total_patients={(global_patient?.total_users ?? 0) - (global_patient?.pending_users ?? 0)}
                                        total_patients={global_patient?.total_users ?? 0}
                                        attending_patients={(global_patient?.total_users ?? 0) - (global_patient?.pending_users ?? 0)}

                                    />
                                    <ExecutivePatientsInCare 
                                        imc={average_metrics?.average_imc}
                                        weigth={average_metrics?.average_weight}
                                        icc_female={average_metrics_female?.average_icc}
                                        icc_male={average_metrics_male?.average_icc}
                                        fat_percentage={average_metrics?.average_percentage_fat}
                                        appointments={average_metrics?.total_appointments}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={3}>
                                    <ExecutiveLocationCard 
                                        locations={location_patient}
                                    />
                                    <ExecutiveObjectivesCard
                                        objectives={users_objectives}
                                    />
                                </Stack>
                                
                            </Grid>
                        </Grid>
                    </LoadingContainer>
            </Box>
            
        </Box>
     );
}
 
export default ExecutiveReport;