import { useCallback, useEffect, useState } from "react";
import useInitState from "../../../../hooks/useInitState";
import useTableSettings from "../../../../hooks/useTableSettings";
import { getAppointments } from "../../../../services/appointmentService";
import moment from "moment";

const useAppointmentsProgramSection = ({
    idclient,
    idclientprogram,
}) => {

    const {
        loading,
        submitting,
        error,
        finish
    } = useInitState()
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [period, setPeriod] = useState({
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().endOf('month').format('YYYY-MM-DD')
    })
    const tableSettings = useTableSettings({
        initial_params:{limit:100, offset:0, order:1, order_by:JSON.stringify(['start_date'])}
    })

    const onUpdatePeriod = useCallback((data) => {
        tableSettings.onUpdatePaginationValue('offset', 0)
        setPeriod(data)
    }, [])

    const onUpdatePagination = useCallback((data) => {
        tableSettings.setPagination(data)
    }, [tableSettings])

    const onGetParams = useCallback(() => {
        let _params = {...tableSettings.pagination}
        _params.offset = _params.offset * _params.limit
        let ANDfilters = [
            {field:'client_id', operator:"=", value:idclient},
            {field:'start_date', operator:">=", value:period.start_date},
            {field:'end_date', operator:"<=", value:period.end_date},
        ]
        console.log(_params)
        _params.filter = JSON.stringify({AND:ANDfilters})
        return _params

    }, [tableSettings, idclient, period])

    const onRequestAppointments = useCallback(async() => {
        loading.onTrue()
        try {
            const _params = onGetParams()
            console.log("Solicitar appointments")
            console.log(_params)
            const _request = await getAppointments(_params)
            console.log(_request)
            setTotal(_request.count)
            setData(_request.data)
        } catch (error) {
            
        }
        loading.onFalse()
    }, [onGetParams])

    const initialize = useCallback(async() => {
        loading.onTrue()
        try {
            const _params = onGetParams()
            console.log("Solicitar appointments")
            console.log(_params)
            const _request = await getAppointments(_params)
            console.log(_request)
            setTotal(_request.count)
            setData(_request.data)
        } catch (error) {
            console.log(error)
        }
        loading.onFalse()
        finish.onTrue()
    }, [loading])

    useEffect(() => {
        if(finish.value){
            window.scrollTo({top:0, behavior:'smooth'})
            onRequestAppointments()
        }
    }, [tableSettings.pagination, period])

    useEffect(() => {
        initialize()
    }, [idclient, idclientprogram])

    return {
        data, 
        loading,
        total,
        pagination:tableSettings.pagination,
        onUpdatePagination:onUpdatePagination,
        period,
        setPeriod,
        onUpdatePeriod
    };
}
 
export default useAppointmentsProgramSection;