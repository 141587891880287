import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { catalogs } from '../../../utils/catalogs';
import CustomSmallSelect from '../../custom-input/CustomSmallSelect';
import { useBoolean } from '../../../hooks/useBoolean';
import LoadingContainer from '../../loaders/LoadingContainer';
import { onGetAnalyticsRangeDates } from '../../../utils/general';
import { getAnalyticsAvergaMetrics, getAnalyticsLocationAverageMetrics } from '../../../services/analyticsService';
import AnalyticsSimpleTable from '../AnalyticsSimpleTable';
import { fNumber } from '../../../utils/formatNumber';
import { onGetIMCResult } from '../../../utils/platform';

const AnnexByObjectiveReport = ({
    idclient, 
    idclientprogram, 
    period
}) => {

    const [selected, setSelected] = useState(1)
    const [data, setData] = useState([])
    const [count_current, setCountCurrent] = useState(0)
    const [count_previous, setCountPrevious] = useState(0)

    const loading = useBoolean()

    const initialize = useCallback(async() => {
            const dates = onGetAnalyticsRangeDates(period)
            loading.onTrue()
            const current_params = {
                start_date:dates.current.start_date,
                end_date:dates.current.end_date,
                client_program_id:idclientprogram,
                objective_id:selected
            }
            //console.log(current_params)
            let current_metrics
            let previous_metrics
            try {
                current_metrics = await getAnalyticsAvergaMetrics(current_params)
            } catch (error) {
                console.log(error)
            }

            const previous_params = {
                start_date:dates.previous.start_date,
                end_date:dates.previous.end_date,
                client_program_id:idclientprogram,
                objective_id:selected
            }
            try {
                previous_metrics = await getAnalyticsAvergaMetrics(previous_params)
            } catch (error) {
                console.log(error)
            }

            

            // Weight
            const current_average_weight = Number(current_metrics.average_weight??0);
            const previous_average_weight = Number(previous_metrics.average_weight??0);
            
            const _imc_diagnostic = onGetIMCResult(current_average_weight)

            // IMC
            const current_average_imc = current_metrics.average_imc;
            const previous_average_imc = previous_metrics.average_imc;

             // waist - cintura
             const current_average_waist = current_metrics.average_circumference_waist;
             const previous_average_waist = previous_metrics.average_circumference_waist;

              // hips - cadera
            const current_average_hips = current_metrics.average_circumference_hips;
            const previous_average_hips = previous_metrics.average_circumference_hips;

            // ICC
            const current_average_icc = current_metrics.average_icc;
            const previous_average_icc = previous_metrics.average_icc;

            // & fat
            const current_average_percentage_fat = current_metrics.average_percentage_fat;
            const previous_average_percentage_fat = previous_metrics.average_percentage_fat;

            // Weight
            const current_average_lean = current_metrics.average_lean_mass;
            const previous_average_lean = previous_metrics.average_lean_mass;
            

            let _data = [
                ["Promedio de peso", `${fNumber(previous_average_weight, '0.00')}kg`, `${fNumber(current_average_weight, '0.00')}kg`, "/"],
                ["Promedio de IMC", `${fNumber(previous_average_imc, '0.00')}kg/mt2`, `${fNumber(current_average_imc, '0.00')}kg/mt2`, _imc_diagnostic],
                ["Promedio de circunferencia de cintura", `${fNumber(previous_average_waist, '0.00')}cm`, `${fNumber(current_average_waist, '0.00')}cm`, "/"],
                ["Promedio de circunferencia de cadera", `${fNumber(previous_average_hips, '0.00')}cm`, `${fNumber(current_average_hips, '0.00')}cm`, "/"],
                ["Promedio de ICC", `${fNumber(previous_average_icc, '0.00')}`, `${fNumber(current_average_icc, '0.00')}`, "/"],
                ["Promedio de % de grasa", `${fNumber(previous_average_percentage_fat, '0.00')}%`, `${fNumber(current_average_percentage_fat, '0.00')}%`, "/"],
                ["Promedio de kg de masa libre de grasa", `${fNumber(previous_average_lean, '0.00')}kg`, `${fNumber(current_average_lean, '0.00')}kg`, "/"],
            ]
            setData(_data)

            loading.onFalse()
    
    },[period, selected, idclient, idclientprogram])

    useEffect(() => {
        initialize()
    }, [selected, period])

    return ( 
        <Box>
            <Stack direction='row' >
                <Typography sx={{flex:1}} variant='h5'>
                    Diagnóstico antropométrico
                </Typography>
                <CustomSmallSelect label='Grupo' value={selected} onChange={(e) => setSelected(Number(e.target.value))}>
                    {catalogs.objectives.map((objective) => (
                        <option key={objective.value} value={objective.value}>
                            {objective.label}
                        </option>
                    ))}
                </CustomSmallSelect>
                
                
            </Stack>
            <LoadingContainer loading={loading.value}>
                <Box sx={{mt:3}}>
                    <AnalyticsSimpleTable 
                        head={[
                            'Dato antropométrico','Hallazgo mes previo','Hallazgo mes actual','Diagnóstico'
                        ]}
                        data={data}
                    />
                </Box>
            </LoadingContainer>
        </Box>
     );
}
 
export default AnnexByObjectiveReport;