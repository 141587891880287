import { Box, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react'
import moment from 'moment';
import CustomSmallSelect from '../custom-input/CustomSmallSelect';

const AnalyticsHeader = ({
    title,
    period,
    onChange
}) => {

    const [months, setMonths] = useState(monthsCat)
    const [years, setYears] = useState(ongetyearscatalog())

    const [periodName, setPeriodName] = useState("")

    const onChangeMonth = useCallback((e) => {
        let result = {...period}
        result.month = parseInt(e.target.value)
        onChange(result)
    }, [period])

    const onChangeYear = useCallback((e) => {
        let result = {...period}
        result.year = parseInt(e.target.value)
        onChange(result)
    }, [period])




    return ( 
        <Box sx={{pb:2}}>
            <Stack direction={'row'} spacing={2} alignItems='center'>
                <Box sx={{
                    flex:1
                }}>
                    <Typography variant='h4'>
                        {title}
                    </Typography>
                    <Typography>
                        {periodName}
                    </Typography>
                </Box>
                
                <Stack direction={'row'} spacing={2} alignItems='center'>
                    <CustomSmallSelect value={period?.month ?? 0} onChange={onChangeMonth}>
                        {months.map((month) => (
                            <option key={month.value} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </CustomSmallSelect>
                    <CustomSmallSelect value={period?.year ?? 0} onChange={onChangeYear}>
                        {years.map((year) => (
                            <option key={year.value} value={year.value}>
                                {year.label}
                            </option>
                        ))}
                    </CustomSmallSelect>
                </Stack>
                
            </Stack>
        </Box>
     );
}
 
export default AnalyticsHeader;

const monthsCat = [
  { value: 0, label: "Enero" },
  { value: 1, label: "Febrero" },
  { value: 2, label: "Marzo" },
  { value: 3, label: "Abril" },
  { value: 4, label: "Mayo" },
  { value: 5, label: "Junio" },
  { value: 6, label: "Julio" },
  { value: 7, label: "Agosto" },
  { value: 8, label: "Septiembre" },
  { value: 9, label: "Octubre" },
  { value: 10, label: "Noviembre" },
  { value: 11, label: "Diciembre" }
];

const ongetyearscatalog = () => {
    const year = moment().year()
    let inityear = 2023
    let result = []
    for(let i=inityear; i<=year; i++){
        result.push({
            value:i,
            label:`${i}`
        })
    }
    return result
}
