
import { useMemo } from "react";
import { useBoolean } from "./useBoolean";
import { useError } from "./useError";


/**
 * @typedef {Object} useErrorReturn
 * @property {Object} loading - The loading object hook.
 * @property {Object} busy - The loading object hook.
 * @property {Object} submitting - The loading object hook.
 * @property {Object} error - The loading object hook.
 */

/**
 * A custom hook to manage init state.
 *
 * @returns {useErrorReturn} - An object containing the boolean state and utility functions.
 */

const useInitState = () => {

    const loading = useBoolean()
    const finish = useBoolean()
    const busy = useBoolean()
    const submitting = useBoolean()
    const error = useError()

    const memoizedValue = useMemo(
        () => ({
        finish,
        loading,
        busy,
        submitting,
        error
        }),
        [loading, busy, submitting, error, finish]
    );

    return memoizedValue;

}
 
export default useInitState;