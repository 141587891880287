import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { fNumber } from "../../../utils/formatNumber";

const ExecutivePatientsInCare = ({
    imc=0,
    weigth=0,
    icc_female=0,
    icc_male=0,
    fat_percentage=0,
    appointments=0,

}) => {
    return ( 
        <Box>
            <Typography variant='h6' gutterBottom>
                Datos generales de colaboradores en atención
            </Typography>
            <Card sx={{p:2, border:`1px solid ${blueGrey[300]}`}}>
                <Stack spacing={2}>
                    <DataEntry 
                        text={"1. IMC global octubre:"}
                        value={fNumber(imc,'0.00')}
                    />
                    <Divider />
                    <DataEntry 
                        text={"2. Promedio de peso:"}
                        value={fNumber(weigth,'0.00')+' kg'}
                    />
                    <Divider />
                    <DataEntry 
                        text={"3. ICC global género femenino:"}
                        value={fNumber(icc_female,'0.00')}
                    />
                    <Divider />
                    <DataEntry 
                        text={"4. ICC global género masculino:"}
                        value={fNumber(icc_male,'0.00')}
                    />
                    <Divider />
                    <DataEntry 
                        text={"5. Promedio de % de grasa:"}
                        value={fNumber(fat_percentage,'0.00')}
                    />
                    <Divider />
                    <DataEntry 
                        text={"6. Consultas brindadas el periodo:"}
                        value={appointments}
                    />
                    <Divider />
                    
                </Stack>
                
                
            </Card>
        </Box>
     );
}
 
export default ExecutivePatientsInCare;

const DataEntry = ({
    text,
    value
}) => {
    return(
        <Grid container>
            <Grid item xs={12} md={8}>
                <Typography >
                    {text}
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack alignItems='flex-end'>
                    <Typography align='right' sx={{
                        background: theme => theme.palette.primary.main,
                        px:2, color:'white',
                        borderRadius:1
                    }}>
                        {value}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}